<template>
  <notification-item :message="message" @delete-item="deleteItem" @close="close">
    <template v-slot:title>
      <span>{{ $t('_.title') }}</span>
    </template>
    <p v-t:_.statusMessage="shopData" class="main-msg"></p>
    <p v-t:_.detailMessage class="msg"></p>
    <p v-t:_.otherMessage class="sub-msg"></p>
  </notification-item>
</template>

<script>
import Specific from '@shared/types/Specific';
import NotificationItem from '@shared/components/notification/NotificationItem.vue';

export default {
  name: 'ShopItemBuyItem',
  components: { NotificationItem },
  lexicon: 'shared.notification.SHOP_ITEM_BUY',
  props: {
    message: Specific,
  },
  computed: {
    /** @type {NotificationShopData} */
    shopData() {
      return this.message.data;
    },
  },
  methods: {
    deleteItem(notificationId) {
      this.$emit('delete-item', notificationId);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';

</style>
